// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-brand-assets-index-js": () => import("./../../../src/pages/brand/assets/index.js" /* webpackChunkName: "component---src-pages-brand-assets-index-js" */),
  "component---src-pages-brand-guides-index-js": () => import("./../../../src/pages/brand/guides/index.js" /* webpackChunkName: "component---src-pages-brand-guides-index-js" */),
  "component---src-pages-brand-story-index-js": () => import("./../../../src/pages/brand/story/index.js" /* webpackChunkName: "component---src-pages-brand-story-index-js" */),
  "component---src-pages-community-index-js": () => import("./../../../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-get-started-index-js": () => import("./../../../src/pages/get-started/index.js" /* webpackChunkName: "component---src-pages-get-started-index-js" */),
  "component---src-pages-glossary-index-js": () => import("./../../../src/pages/glossary/index.js" /* webpackChunkName: "component---src-pages-glossary-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-index-js": () => import("./../../../src/pages/manifesto/index.js" /* webpackChunkName: "component---src-pages-manifesto-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-roadmap-index-js": () => import("./../../../src/pages/roadmap/index.js" /* webpackChunkName: "component---src-pages-roadmap-index-js" */),
  "component---src-pages-token-index-js": () => import("./../../../src/pages/token/index.js" /* webpackChunkName: "component---src-pages-token-index-js" */),
  "component---src-templates-verification-js": () => import("./../../../src/templates/verification.js" /* webpackChunkName: "component---src-templates-verification-js" */)
}

